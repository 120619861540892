import './App.css'
import logo from './salesforce-logo.png'
import React, { Component } from 'react'
import Helpers from './Helpers.js'
import { CSVLink } from "react-csv";

// https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
class App extends Component {
  constructor(props) {
    super(props)

    let leftValuesDevAndQA = [
                                "00D63000000TdpUEAS - CCNA CMR",
                                "00D3I0000000Ok1UAE - COE Org",
                                "00D3K0000000MQCUA2 - TCCC Application",
                                "00D2C000000CxAaUAK - CCNA IT"
                            ];
    let leftValuesProd = [ 
                          "00DF0000000gQEH - The Coca Cola Company - Brazil",
                          "00D60000000IyVi - TCCS Org",
                          "00Di0000000hPOM - SMKT Org (CokeWorks)",
                          "00Dd0000000hh3T - HRO Org",
                          "00DE0000000ddcA - COE Org",
                          "00Dd0000000fSbw - TCCC Application",
                          "00DA0000000ZuEE - The Coca-Cola Company -- Stakeholders",
                          "00D41000002HRSz - Cstmr Org (GCCL)",
                          "00D6A000002EsuQ - The Coca-Cola Company",
                          "00D610000008NDt - The Coca-Cola Company - CCNA CMR",
                          "00D90000000lUv8 - Coca-Cola Customer Business Solutions",
                          "00D61000000aNH9 - The Coca-Cola Company - Freestyle",
                          "00D24000000qZre - Coca-Cola Icecek",
                          "00D460000016yMT - Corporacion RICA",
                          "00D10000000ZNzG - Coca-Cola East Japan",
                          "00Di0000000KFvA - The Coca-Cola Company - CCNA Food Services",
                          "00Dj0000001sU9T - CCNA IT",
                          "00DG0000000jnuC - Customer and Commercial",
                          "00DE0000000cUIp - The Coca-Cola Company",
                          "00D20000000Ca1C - Coca-Cola Services",
                          "00Di0000000HOUQ - The Coca-Cola System Collaboration",
                          "00DU0000000IrgK - Coca-Cola Europacific Partners",
                          "00DD0000000pMwP - CCEP DE",
                          "00D0O000000sODN - My Performance",
                          "00D1U0000013oMZ - Coca-Cola Bottling Company - United",
                          "00D1U000001016C - Coca-Cola Marketing (MAP)"
                        ];
    let environment = this.getEnvironment();
    console.log(environment);
 
    this.state = {
      rightValues: [],
      leftValues: environment === "prod" ? leftValuesProd : leftValuesDevAndQA,
      rightButtonDisabled: true,
      leftButtonDisabled: true,
      rightSelectedValue: "",
      leftSelectedValue: "",
      
      selectionSize: 4,
      loading: false,
      errors: null,

      data : []
    }

    this.handleChangeRightValueSelected = this.handleChangeRightValueSelected.bind(this);
    this.sendValueToRight = this.sendValueToRight.bind(this);
    this.focusRightValues = this.focusRightValues.bind(this);

    this.handleChangeLeftValueSelected = this.handleChangeLeftValueSelected.bind(this);
    this.sendValueToLeft = this.sendValueToLeft.bind(this);
    this.focusLeftValues = this.focusLeftValues.bind(this);

    this.download = this.download.bind(this);

    this.csvLinkAuditForceLog = React.createRef();

    console.log(this.state.leftValues);
    console.log(this.state.rightValues);
    
  }

  handleChangeRightValueSelected(e) {
    console.log(`Setting right value: ${e.target.value}`);
    this.setState({rightButtonDisabled: false});
    this.setState({leftButtonDisabled: true});
    this.setState({rightSelectedValue: e.target.value});
    this.logValues();
  }

  sendValueToRight() {
    if(!this.state.rightValues.includes(this.state.rightSelectedValue)) {
      console.log(`Sending value: ${this.state.rightSelectedValue} to right`);
      this.setState({rightButtonDisabled: true});
      this.state.rightValues.push(this.state.rightSelectedValue);
      let leftSideIndex = this.state.leftValues.indexOf(this.state.rightSelectedValue);
      this.state.leftValues.splice(leftSideIndex, 1);
    }
    this.logValues();
  }

  focusRightValues() {
    this.setState({rightButtonDisabled: false});
    this.setState({leftButtonDisabled: true});
  }

  handleChangeLeftValueSelected(e) {
    console.log(`Setting left value: ${e.target.value}`);    
    this.setState({leftButtonDisabled: false});
    this.setState({rightButtonDisabled: true});
    this.setState({leftSelectedValue: e.target.value});
    this.logValues();
  }

  sendValueToLeft() {
    if(!this.state.leftValues.includes(this.state.leftSelectedValue)) {
      console.log(`Sending value: ${this.state.leftSelectedValue} to left`);
      this.setState({leftButtonDisabled: true});
      this.state.leftValues.push(this.state.leftSelectedValue);
      let rightSideIndex = this.state.rightValues.indexOf(this.state.leftSelectedValue);
      this.state.rightValues.splice(rightSideIndex, 1);
    }
    this.logValues();
  }

  focusLeftValues() {
    this.setState({leftButtonDisabled: false});
    this.setState({rightButtonDisabled: true});
  }

  logValues() {
    console.log(`Left Values: ${this.state.leftValues} | Right Values: ${this.state.rightValues}`);
  }

  translateOrgs (orgsString) {
    console.log(`original ${orgsString}`);
    //Dev and QA values
    orgsString = orgsString.replace('00D63000000TdpUEAS - CCNA CMR', 'CMR');
    orgsString = orgsString.replace('00D3I0000000Ok1UAE - COE Org', 'COE');
    orgsString = orgsString.replace('00D3K0000000MQCUA2 - TCCC Application', 'FINA');
    orgsString = orgsString.replace('00D2C000000CxAaUAK - CCNA IT', 'TOOLKIT');

    //Prod values
    orgsString = orgsString.replace('00DF0000000gQEH - The Coca Cola Company - Brazil', 'TCCBRAZIL');
    orgsString = orgsString.replace('00D60000000IyVi - TCCS Org', 'TCCS');
    orgsString = orgsString.replace('00Di0000000hPOM - SMKT Org (CokeWorks)', 'SMKT');
    orgsString = orgsString.replace('00Dd0000000hh3T - HRO Org', 'HRO');
    orgsString = orgsString.replace('00DE0000000ddcA - COE Org', 'COE');
    orgsString = orgsString.replace('00Dd0000000fSbw - TCCC Application', 'TCCCAPP');
    orgsString = orgsString.replace('00DA0000000ZuEE - The Coca-Cola Company -- Stakeholders', 'STAKEHOLDERS');
    orgsString = orgsString.replace('00D41000002HRSz - Cstmr Org (GCCL)', 'CUSTOMER');
    orgsString = orgsString.replace('00D6A000002EsuQ - The Coca-Cola Company', 'TCCC');
    orgsString = orgsString.replace('00D610000008NDt - The Coca-Cola Company - CCNA CMR', 'CMR');    
    orgsString = orgsString.replace('00D90000000lUv8 - Coca-Cola Customer Business Solutions', 'CUSTOMERBUSINESS');
    orgsString = orgsString.replace('00D61000000aNH9 - The Coca-Cola Company - Freestyle', 'FREESTYLE');
    orgsString = orgsString.replace('00D24000000qZre - Coca-Cola Icecek', 'ICECEK');
    orgsString = orgsString.replace('00D460000016yMT - Corporacion RICA', 'RICA');
    orgsString = orgsString.replace('00D10000000ZNzG - Coca-Cola East Japan', 'JAPAN');
    orgsString = orgsString.replace('00Di0000000KFvA - The Coca-Cola Company - CCNA Food Services', 'FOODSERVICES');
    orgsString = orgsString.replace('00Dj0000001sU9T - CCNA IT', 'TOOLKIT');
    orgsString = orgsString.replace('00DG0000000jnuC - Customer and Commercial', 'COMMERCIAL');
    orgsString = orgsString.replace('00DE0000000cUIp - The Coca-Cola Company', 'TCCC2');    
    orgsString = orgsString.replace('00D20000000Ca1C - Coca-Cola Services', 'CCSERVICES');
    orgsString = orgsString.replace('00Di0000000HOUQ - The Coca-Cola System Collaboration', 'COLLABORATION');
    orgsString = orgsString.replace('00DU0000000IrgK - Coca-Cola Europacific Partners', 'EUROPACIFIC');
    orgsString = orgsString.replace('00DD0000000pMwP - CCEP DE', 'CCEPDE');
    orgsString = orgsString.replace('00D0O000000sODN - My Performance', 'MYPERFORMANCE');
    orgsString = orgsString.replace('00D1U0000013oMZ - Coca-Cola Bottling Company - United', 'CCBCU');
    orgsString = orgsString.replace('00D1U000001016C - Coca-Cola Marketing (MAP)', 'MAP');

    console.log(`translated ${orgsString}`);
    return orgsString;
  }

  getEnvironment () {
    let url = window.location.origin;
    console.log(url);

    let environment = "";
    if (url === "https://sause2nacladev0001.z20.web.core.windows.net") {
      environment = "dev";
    } else if (url === "https://sause2naclaqa0001.z20.web.core.windows.net") {
      environment = "qa";
    } else if (url === "https://sause2naclaprod0001.z20.web.core.windows.net") {
      environment = "prod";
    }

    return environment;
  }

  async download() {
    let auditOrgs = this.translateOrgs(this.state.rightValues.join('_'));
    let environment = this.getEnvironment();
    console.log(environment);

    this.setState({
      errors: null,
      loading: true,
    }, () => {
      Helpers.httpRequest(
        `https://tccc-edge-cla-${environment}-01.azureedge.net/api/api?orgs=${auditOrgs}`,
        'post',
      )
      .then((response) => {
        console.log(`response: ${JSON.stringify(response)}`);
        this.setState({data: response.data});
        this.csvLinkAuditForceLog.current.link.click();
        this.setState({loading: false});
      })
      .catch((error) => {
        this.setState({
          errors: error,
          loading: false
        })
      }) 
    })
  }

  render() {
    const { loading, errors } = this.state
    return (
      <body> 
        <div class="outercontainer"> 
          <div class="imgcontainer"> 
            <img src={logo} className="App-logo" alt="logo" />
          </div> 
          <h2>All Orgs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Audit Orgs</h2>
          <section class="checkcontainer">
            <div class="left-vals">
              <select id="leftValues" onChange={this.handleChangeRightValueSelected} size={this.state.selectionSize} onClick={this.focusRightValues}>
                { this.state.leftValues.map(tempValue => <option key={tempValue} value={tempValue} title={tempValue}>{tempValue}</option>) }
              </select>
            </div>
            <div>
              <button disabled={this.state.rightButtonDisabled} onClick={this.sendValueToRight}>
                &gt;&gt;
              </button>
              <button disabled={this.state.leftButtonDisabled} onClick={this.sendValueToLeft}>
                &lt;&lt;
              </button>
            </div>
            <div class="right-vals">
              <select id="rightValues" onChange={this.handleChangeLeftValueSelected} size={this.state.selectionSize} onClick={this.focusLeftValues}>
                { this.state.rightValues.map(tempValue => <option key={tempValue} value={tempValue} title={tempValue}>{tempValue}</option>) }
              </select>
            </div>
          </section>
          <div class="container">
            <button class="button download" onClick={this.download} disabled={loading}>{(loading) ? 'Downloading...' : 'Download'}</button>
            {(errors)
              ? (<div class="error">
                  <p><strong>Error!</strong> {errors.message || 'Something went wrong.'}</p>
                </div>
              )
              : null
            }
            <p>The Salesforce audit tool is designed to automatically capture Force.com license usage across all of the Coke system's orgs.</p>
            <p>If this is done manually, it is a very time consuming process that can take up to 25-30 hrs for each org.</p>
            <p>Using this tool, the entire process is automated.</p>
            <p>As a user, you just have to select an org that you want to run a usage audit and the tool will generate a CSV file with that information.</p>

            <CSVLink data={this.state.data} filename="AuditForceLog.csv" ref={this.csvLinkAuditForceLog} />

          </div>
        </div>
      </body>  
    )
  }
}

export default App;