  
  /**
   * utility function.
   * @param {*} query
   * @return {*}
   */
  export function parseQuery(query) {
    const result = {};
    if (!query) {
      return result;
    }
  
    const queries = query.replace('#', '').split('&');
    queries.forEach(param => {
      const params = param.split('=');
      result[params[0]] = params[1];
    });
    return result;
  }