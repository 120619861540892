/* eslint-disable no-unused-vars */
import { getHost } from './EnvironmentService';
import { parseQuery } from './CommonService';
import atob from 'atob';
import CryptoJS from 'crypto-js';

const hash = window.location.hash;

/**
 * Login to Azure SSO
 */
export const loginToAd = async () => {
  const activeLogin = sessionStorage.getItem('activeLogin');
  const loginUrl = getHost().AUTH_PROVIDER_URL;
  if (!activeLogin) {
    sessionStorage.setItem('activeLogin', 'inProgress');
    window.location.href = loginUrl;
  } else if (activeLogin === 'inProgress') {
    const response = parseQuery(hash);
    // window.history.replaceState({}, 0, window.location.pathname);
    if (response && response.id_token) {
      const apply = await applyCredentials(hash);
      return apply;
    }
    sessionStorage.removeItem('activeLogin');
    return null;
  }
};

export async function applyCredentials() {

  const response = parseQuery(hash);
  if (response && response.id_token) {
    var idToken = response.id_token
  var payload = JSON.parse(atob(idToken.split('.')[1]));
  const sub = payload.sub;
  const claim = payload;
  
 
  sessionStorage.setItem('user-uuid', getAESAction('ASCES', sub, 'ENCRYPT'));
  sessionStorage.setItem('user', encodeURIComponent(JSON.stringify(claim.companyName+claim.unique_name+claim.upn).replace(/\./g,'').replace(/@/g,'').replace(/"/g,'').replace(/undefined/g,'')));
  sessionStorage.setItem('token', idToken);
  sessionStorage.setItem('guap', 'rGHZJsKRzxt9GNI3');  
  wait(200);
  window.location.href='/portal.html'
  return claim;
  }
}

async function applyFakeCredentials(bot) {
  sessionStorage.setItem('user-uuid', getAESAction('ASCES', 'test', 'ENCRYPT'));
  sessionStorage.setItem('user', bot);
  sessionStorage.setItem('token', getAESAction('ASCES', 'rGHZJsKRzxt9GNI3', 'ENCRYPT'));
  wait(200);
  window.location.href='/portal.html'
  return;
}

export function getAESAction(pass, stringTODO, action) {
  if (!stringTODO) {
    return;
  }
  if (action === 'ENCRYPT') {
    return CryptoJS.AES.encrypt(stringTODO, pass).toString();
  } else {
    return CryptoJS.AES.decrypt(stringTODO, pass).toString(CryptoJS.enc.Utf8);
  }

}

function wait(ms){
  var start = new Date().getTime();
  var end = start;
  while(end < start + ms) {
    end = new Date().getTime();
 }
}

/**
 * Logout from Azure SSO
 */
export const logoutFromAd = () => {
  sessionStorage.removeItem('activeLogin');
  sessionStorage.removeItem('user-uuid');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
  window.location.href = `${getHost().LOGOUT_URL}?post_logout_redirect_uri=${window.location.origin}`;
};