import React from "react";
import { Card, Container, Row, Col } from "reactstrap";
import Loader from 'react-loader-spinner'
import { loginToAd } from '../services/AuthenticationService'

function Login() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    const activeLogin = sessionStorage.getItem('activeLogin');

    if (!activeLogin) {
      async function login() {
        await loginToAd()
      }
      login();
    } else if (activeLogin === 'inProgress') {
      async function login() {
        await loginToAd()
      }
      login();
    }
  });
  return (
    <>
      <div className="filter" />
      <Container>
        <Row>
          <Col style={{ margin: '0 auto' }} lg="8">
            <Card style={{ margin: '25px auto', textAlign: 'center', }}>
              <h3>Redirecting</h3><Loader type="ThreeDots" color="slategray" height={20} width={20} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
