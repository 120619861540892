/**
 * utility function to be called based on url and environment.
 */
const uuid = require('uuid').v4; 

//TODO: these should be configurable and not hardcoded
const CONFIG = {
  LOCAL: {
    ENDPOINT: 'http://localhost:3000',
    AUTH_PROVIDER_URL: `https://login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d/oauth2/authorize?client_id=8d791e2f-08da-4f85-baa6-473887284fe9&redirect_uri=https://sause2nacladev0001.z20.web.core.windows.net/index.html&response_mode=fragment&scope=openid%20email%20profile&state=12345&nonce=${uuid()}`
  },
  NONPROD: {
    ENDPOINT: 'http://localhost:3000',
    AUTH_PROVIDER_URL: `https://login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d/oauth2/authorize?client_id=9d85839e-a8d7-4a55-b1a6-b6180b26d307&redirect_uri=https://sause2naclaqa0001.z20.web.core.windows.net/index.html&response_mode=fragment&scope=openid%20email%20profile&state=12345&nonce=${uuid()}`
    // AUTH_PROVIDER_URL: `https://login.microsoftonline.com/bf8d6c31-f8d2-4531-be2b-410a0af14907/oauth2/authorize?client_id=3e9fb2ad-1faf-4efc-8e97-6a0bd8231928&response_type=id_token&redirect_uri=http://localhost:3000&response_mode=fragment&scope=openid%20email%20profile&state=12345&nonce=${uuid()}`
  },
  PROD: {
    ENDPOINT: 'http://localhost:3000',
    AUTH_PROVIDER_URL: `https://login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c/oauth2/authorize?client_id=e13f25f0-e82f-4047-bb7e-d1a95f3ab6f6&redirect_uri=https://sause2naclaprod0001.z20.web.core.windows.net/index.html&response_mode=fragment&scope=openid%20email%20profile&state=12345&nonce=${uuid()}`
    // ENDPOINT: 'https://ccna-fab-cona-prod-transformation.s3-us-west-2.amazonaws.com/',
    // AUTH_PROVIDER_URL: `https://login.microsoftonline.com/bf8d6c31-f8d2-4531-be2b-410a0af14907/oauth2/authorize?client_id=9bcbe502-f8d7-4a34-a05a-c034d2e12b00&response_type=id_token&redirect_uri=https://portal.mdm.ko.com&response_mode=fragment&scope=openid&state=12345&nonce=${uuid()}`
  }
  
};

export function isInEnvironment(envList) {
  if (!window.location.origin || window.location.origin === 'null') {
    // Running unit tests
    return false;
  }

  //check if running from a known environment, set env variables based on what is found
  return envList.includes(window.location.origin);
}

//TODO these should be configurable and not hardcoded
const LOCAL_URIS = [
  'https://localhost:3000',
  'http://localhost:3000',
  'https://sause2nacladev0001.z20.web.core.windows.net/',
  'https://sause2nacladev0001.z20.web.core.windows.net',
  'http://sause2nacladev0001.z20.web.core.windows.net',
  'http://sause2nacladev0001.z20.web.core.windows.net/'
];

const NONPROD_URIS = [
  'https://localhost:3000',
  'http://localhost:3000',
  'https://sause2naclaqa0001.z20.web.core.windows.net/',
  'https://sause2naclaqa0001.z20.web.core.windows.net',
  'http://sause2naclaqa0001.z20.web.core.windows.net',
  'http://sause2naclaqa0001.z20.web.core.windows.net/'
];


const PROD_URIS = [
  'https://localhost:3000',
  'http://localhost:3000',
  'https://sause2naclaprod0001.z20.web.core.windows.net/',
  'https://sause2naclaprod0001.z20.web.core.windows.net',
  'http://sause2naclaprod0001.z20.web.core.windows.net',
  'http://sause2naclaprod0001.z20.web.core.windows.net/'
];

export function getHost() {
  // Running without browser in unit test
  if (!window.location.origin || window.location.origin === 'null') {
    return 'Unit-Testing!';
  }

  if (isInEnvironment(LOCAL_URIS)) {
    return CONFIG.LOCAL;
  }

  if (isInEnvironment(NONPROD_URIS)) {
    return CONFIG.NONPROD;
  }

  if (isInEnvironment(PROD_URIS)) {
    return CONFIG.PROD;
  }
  throw new Error('Window origin not recognized in API endpoint map.');
}